import React, { useEffect } from "react"
import { graphql } from "gatsby"
import LayoutLink from "../components/LinkPage/LayoutLink"
import Scroller from "../components/LinkPage/Scroller"
import { useAppState } from "../components/Context"
import Seo from "gatsby-plugin-wpgraphql-seo"

const PageTemplate = (props) => {
  const {
    data: { wpPage },
    pageContext,
    location,
  } = props

  const { setCurrentLinkPage } = useAppState()

  useEffect(() => {
    setCurrentLinkPage(pageContext.uri)
  }, [pageContext.uri, setCurrentLinkPage])

  const pageData = wpPage.template.linkPageData
  const pageMeta = wpPage.pageMeta

  const sources = [
    pageData.backgroundImageMobile.localFile.childImageSharp.fluid,
    {
      ...pageData.backgroundImage.localFile.childImageSharp.fluid,
      media: `(min-width: 992px)`,
    },
  ]

  return (
    <>
      <Seo post={wpPage} />
      <LayoutLink
        isStartPage
        linkData={pageData.links}
        location={location}
        title={pageMeta.title}
        description={pageMeta.desc}
        template={pageContext.template}
        language={pageContext.language}
        image={pageMeta?.image?.localFile?.childImageSharp?.fluid}
        class="front"
      >
        <Scroller
          linkData={pageData.links}
          logoYAdjustment={pageData.logoYAdjustment}
          className="slideWrap"
          image={sources}
          location={props.location}
        ></Scroller>
      </LayoutLink>
    </>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query ($uri: String!) {
    wpPage(uri: { eq: $uri }) {
      pageMeta {
        desc
        title
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, toFormat: JPG, quality: 85) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
          }
        }
      }
      template {
        ... on WpTemplate_NewStartPage {
          templateName
          linkPageData {
            fieldGroupName
            logoYAdjustment
            links {
              fieldGroupName
              isTitle
              title
              link {
                target
                title
                url
              }
              posLeft
              posTop
              menuLevel
            }
            backgroundImage {
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 9600
                    quality: 85
                    toFormat: JPG
                    srcSetBreakpoints: [10, 9600]
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            backgroundImageMobile {
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 4800
                    quality: 85
                    toFormat: JPG
                    srcSetBreakpoints: [10, 7200]
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`
